import i18n from '@/i18n/index'
import constant from '@/utils/constant.js'

export default {
  formData: [{
    title: i18n.t('table.product.basicInformation'),
    name: 0,
    createDateItem: [{
      span: 24,
      prop: 'online',
      value: true,
      name: 'switch',
      label: i18n.t('form.product.online'),
      Object: {}
    }, {
      span: 8,
      prop: 'name',
      name: 'input',
      label: i18n.t('form.decoration.banner'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'begin_date',
      name: 'date',
      label: i18n.t('form.decoration.beginDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {}
    }, {
      span: 8,
      prop: 'end_date',
      name: 'date',
      label: i18n.t('form.decoration.endDate'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {}
    }, {
      span: 8,
      prop: 'platform',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.decoration.platform'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'rank',
      name: 'input',
      type: 'number',
      label: i18n.t('form.decoration.rankingPriority'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'title',
      name: 'input',
      label: i18n.t('form.decoration.mainTitle'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 8,
      prop: 'sub_title',
      name: 'input',
      label: i18n.t('form.decoration.subtitle'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }, {
      span: 24,
      prop: 'remote_image_url',
      name: 'image',
      value: [],
      label: i18n.t('form.product.image'),
      Object: {
        limit: 1
      }
    }, {
      span: 8,
      prop: 'model',
      name: 'select',
      options: [],
      value: [],
      label: i18n.t('form.decoration.targetPage'),
      placeholder: i18n.t('placeholder.defaultSelect'),
      Object: {
        filterable: true
      }
    }, {
      span: 8,
      prop: 'page',
      name: 'input',
      label: i18n.t('form.decoration.customLink'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON,
        visible: 'model',
        visibleValue: 'other'
      }
    }, {
      span: 24,
      prop: 'tips',
      type: 'textarea',
      name: 'input',
      label: i18n.t('form.decoration.tips'),
      placeholder: i18n.t('placeholder.defaultInput'),
      Object: {
        maxlength: constant.FORM_OPTION.INPUT.MAX_LENGTH.COMMON
      }
    }]
  }]
}
